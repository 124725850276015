/* Start Component*/

/* End Component*/

/* Start Glable Rules */

ul {
  margin: 0;
  padding: 0;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  font-family: 'Robote_bold';
  /* background: #dfdfdf !important; */
  margin: 0;
  background-color: #f0f2fc;
  
 
  position: relative;
}

a {
  color: inherit;
  text-decoration: none;
}


button:active {
  outline: none;
}

button:focus {
  outline: none;
}
.swiper {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  width: 280px;
  height: 280px;
  
}

.swiper-slide img {
  display: block;
  width: 100%;
}

.bg-text {
  width: 70%;

  z-index: 2;
  text-align: center;
  color: black;
  font-weight: 600;
 
  padding: 3rem;
  border: none;

  margin: "1rem 0";
}
.black-text input {
  color: black !important;
}
/* 
.black-text .MuiInputBase-input::placeholder {
  color: black !important;
}
.black-border .MuiOutlinedInput-root {
  border-color: black !important;
}

.black-border .MuiOutlinedInput-notchedOutline {
  border-color: black !important;
}

.black-border .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: black !important;
}

.black-border .MuiFormLabel-root.Mui-focused {
  color: black !important;
} */

:root {
  --bg-color: #e5e7eb;
  --button-bg-color: #1a202c;
  --button-text-color: #ffffff;
  --border-color: #000000;
}

.Robote_bold {
  font-family: "Robote_bold",'system-ui';
}

.Robote {
  font-family: "Robote_bold",system-ui;
}
.line {
  height: 20px;
  /* Increased height for better visibility of the curve */
  background-color: black;
  /* margin: 10px 0; */
  width: 200px;
  /* Uniform width for all lines */
  /* padding: 10px; */
}

.bent {
  width: 95%;
  height: 150%;
  border: solid 5px;
  border-color: transparent transparent transparent;
  border-radius: 100% 100% 0 0;
  margin-left: 28px;
  margin-top: -11px;
}

.bent2 {
  width: 95%;
  height: 150%;
  border: solid 5px;
  border-color: transparent transparent transparent;
  border-radius: 100% 100% 0 0;
  margin-left: -22px;
  margin-top: -11px;
}



.swiper-wrapper{
  height: 500px;
}
.swiper{
    height: 500px;

}
.swiper-slide {
  height: auto;
  min-height: 100%; 
}
.Brittany {
  font-family: "Brittany";
}
.inria {
  font-family: "inria";
}
.inria_bold{
  font-family: "inria_bold";
}
.spring{
  font-family: "spring";
}
.spring_bold{
  font-family: "spring_bold";
}
.animate-ring {
  animation: ring 1s ease-in-out infinite;
}

@keyframes ring {
  0% { transform: rotate(0); }
  15% { transform: rotate(5deg); }
  30% { transform: rotate(-5deg); }
  45% { transform: rotate(5deg); }
  60% { transform: rotate(-5deg); }
  75% { transform: rotate(5deg); }
  100% { transform: rotate(0); }
}
.swiper-button-prev, .swiper-button-next {
  position: absolute;
  top: 50%; /* Adjust this value to control the vertical position */
  z-index: 10;
  /* Adjust left/right properties based on the arrow */
}

.swiper-button-prev {
  left: 0px; /* Distance from the left edge */
}

.swiper-button-next {
  right: 0px; /* Distance from the right edge */
}
.mySwiper {
  height: 500px; /* Adjust based on your needs */
  overflow: hidden; /* Prevent scrolling within the swiper container itself */
}
.swiper-slide {
  overflow-y: auto; /* Enable vertical scrolling within each slide */
  height: 100%; /* Ensure slides fill the container, adjust if necessary */
}
.swiper-slide-width {
  width: 300px !important;
}



/* Webkit Browsers (Chrome, Safari) */
#your-scrollable-div-id::-webkit-scrollbar {
  width: 12px; /* Width of the scrollbar */
}

#your-scrollable-div-id::-webkit-scrollbar-track {
  background: #f7f7f7; /* Background color of the scrollbar track */
}

#your-scrollable-div-id::-webkit-scrollbar-thumb {
  background-color: #e5cd79; /* Golden color for the scrollbar thumb */
  border-radius: 10px; /* Rounded corners for the scrollbar thumb */
  border: 3px solid #f7f7f7; /* Space around the scrollbar thumb */
}

/* Firefox */
#your-scrollable-div-id {
  scrollbar-width: thin;
  scrollbar-color: #e5cd79 #f7f7f7; /* thumb color track color */
}

/* Edge and Internet Explorer */
#your-scrollable-div-id {
  -ms-overflow-style: -ms-autohiding-scrollbar; /* Auto-hide the scrollbar */
}
.skew-container {
  transform: skewX(25deg);
}

.skew-containers {
  transform: skewX(-25deg);
}

.svg {
  transform: rotate(180deg);
}

.curved {
  position: relative;
  background: #2c3e50;
  height: 40vh;
  border-bottom-left-radius: 50% 25%;
  border-bottom-right-radius: 50% 25%;
}


.active-link {
  background-color: #D9A31F;
  color: white;
  margin: 10px;
  width: 82%;
  height: 80%;
  margin-left: 10px;
  border-radius: 10px;
  position: relative; /* Required for absolute positioning of the pseudo-element */
}

.active-link::after {
  content: '';
  position: absolute;
  right: -25px; /* Adjust based on your layout */
  top: 50%;
  transform: translateY(-50%);
  width: 10px;
  height: 40px;
  background-color: #D9A31F;
border-radius: 10px;

}
.active-link-arabic {
  background-color: #D9A31F;
  color: black;
  margin: 10px;
  width: 82%;
  height: 80%;
  margin-left: 40px;
  border-radius: 10px;
  position: relative; /* Required for absolute positioning of the pseudo-element */
}

.active-link-arabic::after {
  content: '';
  position: absolute;
  left: -25px; /* Adjust based on your layout */
  top: 50%;
  transform: translateY(-50%);
  width: 10px;
  height: 40px;
  background-color: #D9A31F;
border-radius: 10px;

}




.thumbnailSwiper .swiper-slide {
  width: calc((100% / 4.5) - (10px * 4 / 4.5)); /* Adjust width to show 4.5 slides at once, account for space between */
}

.thumbnailSwiper {
  overflow: hidden; /* Hide the part of the fifth slide that overflows */
}


.customButton {
  position: relative;
  text-align: right;
  background: #262626;
  color: #fff;
}

.customButton:hover {
  background: #3a3a3a; /* Example hover color */
}
.css-1l3ku2r-MuiButtonBase-root-MuiChip-root .MuiChip-deleteIcon {
  margin: 0 !important;
}

@keyframes shake {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-50px);
  }
  50% {
    transform: translateX(5px);
  }
  75% {
    transform: translateX(-5px);
  }
  100% {
    transform: translateX(0);
  }
}

.shake {
  animation: shake 0.5s ease;
}