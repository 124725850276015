.nav {
  position: relative;
  background-color: #fff;
  border-radius: 60px;
  overflow: hidden;
  width: 410px;
  height: 892px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.header {
  width: 100%;
  height: 59px;
  background-color: #fff;
  border-top-right-radius: 60px;
  border-top-left-radius: 60px;
  display: flex;
  justify-content: center;
  position: absolute;
  top: 0;
  z-index: 100;
}

.header img {
  transform: rotateY(180deg) scale(1.3);
}