/* Basic styling */
@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  /* Colors */
  --black: #fff;
  --grey: #fff;
  --white: #fff;
  --background: "#d6cfbf";
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
} 

body {
  background-color: var(--background);
  font-family: "Robote_bold", Robote_bold;
}

@font-face {
  font-family: "Robote_bold";
  src: url("Fonts/Cairo-VariableFont_slnt,wght.ttf") format("truetype");
}

@font-face {
  font-family: "Robote";
  src: url("./Fonts/Cairo-VariableFont_slnt,wght.ttf") format("truetype");
}

@font-face {
  font-family: "Brittany";
  src: url("./Fonts/Cairo-VariableFont_slnt,wght.ttf") format("truetype");
}
@font-face {
  font-family: "inria";
  src: url("./Fonts/Cairo-VariableFont_slnt,wght.ttf") format("truetype");
}
@font-face {
  font-family: "inria_bold";
  src: url("./Fonts/Cairo-VariableFont_slnt,wght.ttf") format("truetype");
}
@font-face {
  font-family: "spring";
  src: url("./Fonts/Cairo-VariableFont_slnt,wght.ttf") format("truetype");
}
@font-face {
  font-family: "spring_bold";
  src: url("./Fonts/Cairo-VariableFont_slnt,wght.ttf") format("truetype");
}
@font-face {
  font-family: "myanmar";
  src: url("./Fonts/Cairo-VariableFont_slnt,wght.ttf") format("truetype");
}

@font-face {
  font-family: "mvboli";
  src: url("./Fonts/Cairo-VariableFont_slnt,wght.ttf") format("truetype");
}

@font-face {
  font-family: "PMINGLIU";
  src: url("./Fonts/Cairo-VariableFont_slnt,wght.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins-Bold";
  src: url("./Fonts/Cairo-VariableFont_slnt,wght.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins-Light";
  src: url("./Fonts/Cairo-VariableFont_slnt,wght.ttf") format("truetype");
}


@font-face {
  font-family: "Rubik-Light";
  src: url("./Fonts/Cairo-VariableFont_slnt,wght.ttf") format("truetype");
}

@font-face {
  font-family: "MTCORSVA";
  src: url("./Fonts/Cairo-VariableFont_slnt,wght.ttf") format("truetype");
}

@font-face {
  font-family: "REFSAN";
  src: url("./Fonts/Cairo-VariableFont_slnt,wght.ttf") format("truetype");
}


@font-face {
  font-family: "Acumin-BdItPro";
  src: url("./Fonts/Cairo-VariableFont_slnt,wght.ttf") format("truetype");
}
/* In your CSS file */
.hide-scrollbar {
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For IE and Edge */

  /* For Chrome, Safari, and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
}
@media (max-width: 768px) {
  .hide-scrollbar {
    padding-left: 20px; /* Smaller padding for small screens */
    padding-right: 20px;
  }

}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.dark-overlay {
  background-color: rgba(0, 0, 0, 0); 
}

.color-container {
  @apply flex items-center justify-center px-4 py-4 rounded-full bg-gray-300 backdrop-blur
}

.flex-center {
  @apply flex items-center justify-center
}