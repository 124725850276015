.scrollable-container {
  width: 90%; /* This will be overridden by inline styles */
  height: 90%; /* This will be overridden by inline styles */
  overflow-y: auto; /* Allows vertical scrolling if needed */
  box-sizing: border-box; /* Includes padding and border in width and height */
  scrollbar-width: none; /* For Firefox */
  border-radius: 60px;
  border: 10px solid hsl(0, 0%, 0%);
position: relative;}
.scrollable-container::-webkit-scrollbar {
  display: none !important; /* For Chrome, Safari, and Opera */
}
